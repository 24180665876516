import React from 'react'
import styled, { css, CSSProp } from 'styled-components'

const StyledPanels = styled.div`
  ${({ theme }): CSSProp => css`
    display: grid;
    grid-gap: 1.3rem;
    @media only screen and ${theme.breakpoints.fromNormalScreen} {
      grid-template-columns: 1fr 1fr;
    }
  `}
`

const Panels: React.FC = ({ children }) => (
  <StyledPanels>{children}</StyledPanels>
)

export default Panels
